.jobs-section {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.jobs-section h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.timeline-container {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 0;
}

.timeline-container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px; /* Space for icon */
  width: 2px;
  background: #003A5D; /* Your primary color */
}

.timeline-item {
  position: relative;
  margin-bottom: 5rem; /* Increased space between items */
  padding-left: 60px; /* Space for icon */
}

.timeline-icon {
  position: absolute;
  left: 30px; /* Match this to the left property in .timeline-container::before */
  transform: translateX(-50%); /* Center the icon horizontally */
  width: 20px; /* Size of the icon circle */
  height: 20px; /* Size of the icon circle */
  color: #003A5D; /* Icon color */
  background: white; /* Background color of the icon circle */
  border: 3px solid #003A5D; /* Blue border */
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-content {
  background: #fff; /* White background for boxes */
  border-radius: 8px; /* Rounded corners */
  padding: 1.5rem; /* Increased padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly more pronounced shadow */
  position: relative;
  border: 1px solid #ddd; /* Light border to make the box more prominent */
}

.timeline-content b {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.timeline-content i {
  display: block;
  font-size: 0.9rem;
  color: #666; /* Grey color for date and duration */
  margin-bottom: 0.5rem;
}

.timeline-content p {
  margin: 0.5rem 0;
}

@media (max-width: 600px) {
  .timeline-container::before {
      left: 20px; /* Adjust based on your design */
  }

  .timeline-icon {
      left: 20px; /* Adjust for smaller screens */
      width: 30px; /* Adjust size if needed */
      height: 30px; /* Adjust size if needed */
  }

  .timeline-icon .icon {
      font-size: 0.5rem; /* Adjust icon size for smaller screens */
  }

  .timeline-content {
      padding: 1rem; /* Adjust padding for smaller screens */
  }
}
