/* General styles for the Classes section */
.classes-page {
  padding: 2rem;
  max-width: 900px; /* Increased max width for better readability */
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem; /* Adjusted font size */
  font-weight: 600; /* Slightly bolder */
}

.current-classes, .previous-courses {
  margin-bottom: 2rem;
}

.current-classes ul, .previous-courses ul {
  list-style: disc; /* Bullet points for both sections */
  padding-left: 20px; /* Indentation for bullet points */
}

.current-classes li, .previous-courses li {
  margin-bottom: 0.5rem;
  font-size: 1rem; /* Adjusted font size */
}

.previous-courses {
  margin-top: 2rem; /* Space above the Previous Courses title */
  position: relative;
}

.previous-courses::before {
  content: '';
  position: absolute;
  top: -1rem; /* Position the line above the title */
  left: 0;
  width: 100%;
  height: 1px;
  background: #003A5D; /* Line color matching the primary color */
}

.previous-courses h2 {
  font-size: 1.5rem; /* Font size for Previous Courses title */
  margin-bottom: 1rem; /* Space below the title */
  position: relative; /* To ensure the line is above the title */
  padding-top: 1.5rem; /* Space for the line */
}

.courses-columns {
  display: flex;
  gap: 2rem; /* Space between columns */
}

.column {
  flex: 1; /* Make columns flexible */
}

.column h4 {
  font-size: 1.2rem; /* Larger font size for section titles */
  margin-bottom: 1rem;
  font-weight: 600; /* Slightly bolder */
}

@media (max-width: 600px) {
  .courses-columns {
    flex-direction: column; /* Stack columns on smaller screens */
    gap: 1rem; /* Reduced gap */
  }
}
