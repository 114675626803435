/* General styles for the project page */
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Grid for displaying projects */
.project-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between project cards */
  justify-content: center;
  width: 100%;
}
/* Heading styling */
.projects h1 {
  margin-bottom: 10px; /* Reduce space below the heading */
}

/* Paragraph styling */
.projects p {
  margin-top: 0; /* Remove margin above the paragraph */
  margin-bottom: 20px; /* Adjust space below the paragraph */
  text-align: center;
}

/* Individual project card styling */
.project-item {
  flex: 0 0 100%; /* Default to one project per row */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  height: 450px;
  max-width: 300px; /* Ensure cards stay at 300px width */
  transition: transform 0.2s ease;
}

.project-item:hover {
  transform: scale(1.05);
}

/* Image styling */
.project-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Project details styling */
.project-details {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Title styling */
.project-details h2 {
  margin-bottom: 10px;
  font-size: 1.2em;
  height: 50px;
  overflow: hidden;
}

/* Description styling */
.project-details p {
  margin: 5px 0;
  font-size: 0.9em;
}

.project-details p:first-of-type {
  height: 110px;
  overflow: hidden;
}

/* Link styling */
.project-link {
  display: inline-block;
  margin-top: auto;
  width: 268px;
  padding: 8px 12px;
  background-color: #003A5D;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #005a8c;
}

/* Responsive styling using media queries */
@media (min-width: 480px) {
  .project-item {
    flex: 0 0 48%; /* On small screens, two cards per row */
  }
}

@media (min-width: 768px) {
  .project-item {
    flex: 0 0 32%; /* On medium screens, three cards per row */
  }
}

@media (min-width: 1024px) {
  .project-item {
    flex: 0 0 24%; /* On large screens, four cards per row */
  }
}
