.techStackContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .techStackHeader {
    text-align: center;
  }
  
  .techStackGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .techStackItem {
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .techStackImage {
    width: 100px;
    height: 100px;
    transition: transform 0.3s ease;
  }
  
  .techStackItem:hover .techStackImage {
    transform: scale(1.1);
  }
  