/* General styles */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 20px;
  padding-top: calc(4rem + 20px); /* Adjust padding-top to accommodate header height */
  max-width: 1200px; /* Limit maximum width */
  margin: 0 auto; /* Center container horizontally */
}

/* Header Section */
.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.headshot-container {
  margin-bottom: 20px;
}

.headshot-image {
  max-width: 200px; /* Adjust size as needed */
  height: auto;
  border-radius: 50%; /* Circular headshot */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.intro-text {
  max-width: 600px; /* Limit text content width */
}

/* Button styles */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f8c6d0; /* Light pink */
  color: #333; /* Dark text color for contrast */
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  text-align: center;
}

.button:hover {
  background-color: #e2a5b2; /* Slightly darker pink */
  transform: scale(1.05); /* Slightly scale button on hover */
}

/* Resume Button */
.resume-button {
  background-color: #f8c6d0; /* Light pink */
}

.resume-button:hover {
  background-color: #e2a5b2; /* Slightly darker pink */
}

/* Contact Info Button */
.contact-button {
  background-color: #d1d1d1; /* Light grey */
}

.contact-button:hover {
  background-color: #b0b0b0; /* Slightly darker grey */
}

/* PDF section styles */
.info-section {
  text-align: center;
}

.pdf-frame {
  width: 100%;
  height: 500px;
  border: none;
}

.download-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f8c6d0; /* Light pink */
  color: #333; /* Dark text color for contrast */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}

.button:hover, .download-button:hover {
  background-color: #e2a5b2; /* Slightly darker pink */
  transform: scale(1.05); /* Slightly scale button on hover */
}

/* PDF section styles */
.download-button {
  background-color: #f8c6d0; /* Light pink */
  color: #333; /* Dark text color for contrast */
  text-decoration: none;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-link {
  display: flex;
  align-items: center;
}

.social-icon {
  width: 40px;
  height: 40px;
  transition: opacity 0.3s ease;
}

.social-icon:hover {
  opacity: 0.7; /* Slight opacity on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
  }

  .headshot-image {
    max-width: 150px;
  }
}
