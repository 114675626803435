.header {
    position: fixed; /* Fixes the header at the top */
    top: 0;
    left: 0;
    right: 0;
    background: white; /* White background for the header */
    padding: 0.5rem; /* Padding around the header */
    color: black; /* Text color matching the primary color */
    display: flex;
    justify-content: center; /* Center contents horizontally */
    z-index: 3000; /* Ensure it's above other content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Shadow for depth effect */
}

.navbar-container {
    background: #FAF9F6; /* Background color of the smaller rectangle */
    border-radius: 12px; /* Rounded corners for the rectangle */
    padding: 0.5rem; /* Padding inside the rectangle */
    display: flex;
    align-items: center;
    max-width: 800px; /* Max width of the navbar */
    width: 100%; /* Full width constrained by max-width */
    margin: 0 auto; /* Center the rectangle horizontally */
}

.navbar {
    display: flex;
    gap: 1rem; /* Space between buttons */
    align-items: center;
    justify-content: center; /* Center the buttons horizontally */
    width: 100%; /* Full width to ensure proper centering */
}

.navbar button {
    background: #F8C6D0; /* Light pink background for buttons */
    border: 2px solid #F8C6D0; /* Light pink border to match background */
    border-radius: 5px; /* Rounded corners for the boxes */
    color: #003A5D; /* Dark text color */
    font-size: 0.8rem; /* Smaller font size */
    padding: 0.5rem; /* Padding to make buttons look like boxes */
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease;
    width: 7rem; /* Fixed width for boxes */
    height: 3rem; /* Fixed height for boxes */
    display: flex; /* Flexbox for centering content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    text-align: center; /* Center text horizontally */
    line-height: 1.2; /* Adjust line height to center text vertically */
    font-weight: bold; /* Make text bold for better visibility */
}

.navbar button:hover {
    background: white; /* White background on hover */
    color: #003A5D; /* Dark color text on hover */
}

.profile-button {
    background: #F8C6D0; /* Light pink background for profile button */
    border: 2px solid #F8C6D0; /* Light pink border to match background */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .header {
      padding: 0.3rem; /* Reduced padding */
    }
  
    .navbar-container {
      padding: 0.3rem; /* Reduced padding inside the navbar container */
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .navbar {
      flex-direction: row; /* Stack buttons vertically */
      gap: 0.5rem; /* Reduced space between buttons */
    }
  
    .navbar button {
      font-size: 0.7rem; /* Smaller font size */
      width: 6rem; /* Reduced width */
      height: 2.5rem; /* Reduced height */
    }
}
