.contact-me-container {
  padding: 20px;
  width: 700px; /* Container's maximum width */
  margin: 0 auto; /* Center container horizontally */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%; /* Make the text boxes take full width of the container */
  padding: 0.75rem; /* Add padding inside the text boxes */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ccc; /* Light grey border */
  font-size: 1rem; /* Font size */
}

.contact-form textarea {
  height: 150px; /* Make the textarea taller */
  resize: vertical; /* Allow vertical resizing */
}

.contact-form button {
  background: #F8C6D0; /* Light pink background for the button */
  border: none;
  border-radius: 5px; /* Rounded corners */
  color: #003A5D; /* Dark text color */
  font-size: 1rem; /* Font size */
  padding: 0.75rem; /* Padding inside the button */
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.contact-form button:hover {
  background: rgb(186, 36, 116); /* Darker pink background on hover */
  color: #003A5D; /* Dark color text on hover */
}

/* Responsive adjustments */
@media (max-width: 700px) {
  .contact-me-container {
    width: calc(100%); /* Container width 100px less than screen width */
  }
}
