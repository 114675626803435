/* src/App.css */
.app {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .SearchHeading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .SearchContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .SearchBar {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="number"] {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px; /* Add some spacing between input fields */
  }
  
  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* App.css */

/* App.css */

/* Prevent horizontal scrolling */
body, html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* General container styling */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  padding: 20px;
  padding-top: calc(4rem + 20px); /* Adjust padding-top to accommodate header height */
  max-width: 100%; /* Ensure it doesn't exceed the screen width */
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0 auto; /* Center container horizontally */
}

/* Other styles remain unchanged */
